import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"

export default function BlockImage({image, lang}) {
    const asset = image && image.asset ? image.asset : {}
    let alt = "Kuva"
    if ((image && image.alt && image.alt[lang]) || (image && image.caption && image.caption[lang])) {
        alt = image && image.alt && image.alt[lang] ? image.alt[lang] : image.caption[lang]
    }
    let caption = image && image.caption && image.caption[lang] ? image.caption[lang] : ""
    const imageWidth = 1800
    
    //Render
    return (
        <div className="image-block">
            {image && <SanityImage asset={asset} alt={alt} width={imageWidth} height={imageWidth}/>}
            {caption && <p className="image-caption">{caption}</p>}
        </div>
    )
}
import React from "react"
import { Link } from "gatsby"

export default function EventTitle( {event, lang, defaultSlugs, context}) {

    // Destructure event
    const {
        title,
        template,
        eventType,
        lineup,
        date
    } = event

    // Scheme
    const scheme = event.scheme ? event.scheme : "fill-red"

    // Type & title
    const typeTitle = template === "event-type" && eventType && eventType[lang] ? eventType[lang] : ""
    const eventTitle = title && title[lang] ? title[lang] : ""

    // Lineup
    let lineupElements = []
    let showLineup = false
    const linksToPerson = context === "page"
    if (lineup) {
        lineupElements = lineup.map((group, i) => {

            let groupElements = []
            const groupLabel = group.label && group.label[lang] ? group.label[lang] : ""
            if (group.list) {
                groupElements = group.list.map((person, j) => {
                    if (person.name && person.name[lang]) {
                        const personClass = (i === 0) && (template !== "event-type") ? `title-highlight ${scheme}` : "general-caption"
                        const comma = (template === "event-type") && (j !== group.list.length - 1) ? "," : ""
                        if (linksToPerson) {
                            return (
                                <li className={personClass} key={person._id}>
                                    <Link to={`/${lang}/${defaultSlugs.people}/${person.slug.current}`}>
                                        {person.name[lang]}
                                    </Link>
                                    {comma}
                                </li>
                            )
                        } else {
                            return (
                                <li className={personClass} key={person._id}>
                                    {person.name[lang]}
                                </li>
                            )
                        }
                    } else return null
                })
            }

            if (groupElements.length > 0) {
                showLineup = (template !== "event-type") || (context === 'page')
                context === 'schedule' && template === 'one-headliner' && (groupElements = groupElements.slice(0, 1))
                return (
                    <div key={group._key} className="lineup-group">
                        {groupLabel && context === 'page' && <h3 className="section-caption">{groupLabel}</h3>}
                        <ul className="lineup-list">
                            {groupElements}
                        </ul>
                    </div>
                )
            } else return null
        })
    }
    context === 'schedule' && (lineupElements = lineupElements.slice(0, 1))
    
    // Date
    const day = new Date(date.day).getUTCDate()
    const month = new Date(date.day).getUTCMonth() + 1
    const time = date.start ? date.start : ""
    const showDate = context === 'schedule'
    const dateElement = (
        <p className="event-date">
            <span className={`day date-caption ${scheme}`}>{`${day}.`}</span>
            <span className={`month date-caption ${scheme}`}>{month}</span>
            {time && <span className="time general-caption">{time}</span>}
        </p>
    )
    // Time

    // Context and template
    const titleClass = `event-title-block ${context}-context ${template}`

    return (
        <div className={titleClass}>
            {showDate && showLineup && ( dateElement )}
            <div className="title-lineup">
                <h1 className="event-title-wrapper">
                    {typeTitle && <span className={`event-type title-highlight ${scheme}`}>{typeTitle}</span>}
                    {eventTitle && <span className="event-title title">{eventTitle}</span>}
                </h1>
                {showLineup && <div className="lineup">{lineupElements}</div>}
            </div>
            {showDate && !showLineup && ( dateElement )}
        </div>
    )
}

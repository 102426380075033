import React from "react"
import { Link } from "gatsby"
import EventTitle from "./EventTitle"
import BlockImage from "./BlockImage"
import Button from "./Button"

export default function EventHeader( {event, lang, defaultSlugs}) {

    // Destructure event
    const {
        section,
        date,
        location,
        cover,
        label,
        button
    } = event
    // Section
    const sectionSlug = section ? `/${lang}/${section.slug.current}` : ""
    const firstTabSlug = section && section.tabs[0] ? `${sectionSlug}/${section.tabs[0].slug.current}` : sectionSlug
    const scheduleLink = section.tabs.find(tab => tab.type === "Schedule")
    const scheduleSlug = scheduleLink ? `${sectionSlug}/${scheduleLink.slug.current}` : ""
    // Date and time
    const day = new Date(date.day).getUTCDate()
    const month = new Date(date.day).getUTCMonth() + 1
    let time = date.start ? date.start : ""
    date.end && (time += " - " + date.end)
    // Label
    const labelText = label && label[lang] ? label[lang] : ""
    // Location
    const locationText = location && location.text && location.text[lang] ? location.text[lang] : ""
    const locationUrl = location && location.url ? location.url : ""
    // Scheme
    const scheme = event.scheme ? event.scheme : "fill-red"


    return (
        <section className="event-header">
            <div className="event-section">
                {section.title[lang] && <Link to={firstTabSlug} className="section-caption">{section.title[lang]}</Link>}
                {scheduleLink && <span>&nbsp;/&nbsp;</span>}
                {scheduleLink && <Link to={scheduleSlug} className="section-caption">{scheduleLink.title[lang]}</Link>}
                {labelText && <span>&nbsp;/&nbsp;</span>}
                {labelText && <span className="section-caption">{labelText}</span>}
            </div>
            <div className="event-cover">
                <BlockImage image={cover} lang={lang} />
                <p className={`day date-caption ${scheme}`}>{`${day}.`}</p>
                <p className={`month date-caption ${scheme}`}>{month}</p>
            </div>
            <EventTitle event={event} lang={lang} defaultSlugs={defaultSlugs} context="page"/>
            <div className="event-details">
                {time && <p className="time general-caption">{time}</p>}
                {locationText &&
                    locationUrl
                        ? <a href={locationUrl} target='_blank' rel="noreferrer noopener nofollow" className={`event-location general-caption`}>{locationText}</a>
                        : <p className={`event-location general-caption`}>{locationText}</p>
                }
            </div>
            <Button button={button} lang={lang} context="event" />
        </section>
    )
}

import React from "react"
import BlockImage from "../components/BlockImage"
import ContactLink from "../components/ContactLink"
import Layout from "../components/Layout"
import ScheduleGrid from "../components/ScheduleGrid"
import Seo from "../components/Seo"

export default function Event({ pageContext }) {

  // Destructure pageContext
  const { lang, person, events } = pageContext
  const {
    photo,
    contacts
  } = person

  // Name, label and description
  const name = person && person.name && person.name[lang] ? person.name[lang] : ""
  const label = person && person.label && person.label[lang] ? person.label[lang] : ""
  const about = person && person.about && person.about[lang] ? person.about[lang] : ""
  // Contacts
  const contactElements = contacts.map(contact => {
    return (
      <li key={contact._key}>
        <ContactLink type={contact.type} url={contact.url} />
      </li>
    )
  })

  return (
      <Layout pageContext={pageContext}>
        <Seo pageContext={pageContext} />
        <section className="person-header">
            {photo && (
              <div className="person-cover">
                  <BlockImage image={photo} lang={lang} />
              </div>
            )}
            <div className="person-title">
                <h1 className={`person-name title-highlight`}>{name}</h1>
                {label && <p className="person-label general-caption">{label}</p>}
            </div>
        </section>
        <section className="person-body">
            {about && <p className="about">{about}</p>}
            {contacts && (
              <ul className={`contacts`}>
                  {contactElements}
              </ul>
            )}
        </section>
        <section className="person-schedule">
          <ScheduleGrid schedule={events} lang={lang} context="person" hidePastEvents={false} />
        </section>
      </Layout>
  )
}

import React from "react"
import {IconFacebook, IconInstagram, IconVkontakte, IconTwitter, IconMail, IconWebsite, IconLinkedin, IconYoutube, IconTiktok} from "../tools/icons"

export default function Icon({ type }) {
      if (type === "facebook") return <IconFacebook />
      else if (type === "instagram") return <IconInstagram />
      else if (type === "vkontakte") return <IconVkontakte />
      else if (type === "twitter") return <IconTwitter />
      else if (type === "email") return <IconMail />
      else if (type === "website") return <IconWebsite />
      // New
      else if (type === "linkedin") return <IconLinkedin />
      else if (type === "youtube") return <IconYoutube />
      else if (type === "tiktok") return <IconTiktok />
      else return <div></div>
}
import React from "react"
import BlockPortableText from "../components/BlockPortableText"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function Content({ pageContext }) {
  // console.log(pageContext)

  // Destructure pageContext
  const { lang, page } = pageContext
  const { body } = page

  return (
      <Layout pageContext={pageContext}>
        <Seo pageContext={pageContext} />
        {body && (
          <section className="content-body">
            <BlockPortableText body={body} lang={lang} />
          </section>
        )}
      </Layout>
  )
}

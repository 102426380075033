import React from "react"
import Layout from "../components/Layout"
import BlockCard from "../components/BlockCard"
import BlockPortableText from "../components/BlockPortableText"
import LinksList from "../components/LinksList"
import LineupGrid from "../components/LineupGrid"
import Seo from "../components/Seo"
import ScheduleGrid from "../components/ScheduleGrid"

export default function Home({ pageContext }) {

  const { home, lang, defaultSlugs } = pageContext

  const bodyElements = home.body && home.body.map((block, index) => {

    switch (block._type) {
      case "card":
        return <BlockCard key={index} card={block} lang={lang} />
      case "localePortableText":
        return <BlockPortableText key={index} body={block} lang={lang} />
      case "linkList":
        return <LinksList key={index} content={block} lang={lang} context="home" />
      case "lineup":
        return <LineupGrid key={index} lineup={block} lang={lang} context="home" defaultSlugs={defaultSlugs} />
      case "eventsList":
        return <ScheduleGrid key={index} schedule={block} lang={lang} context="home" hidePastEvents={true} />
      default:
        console.log("Unknown block type: " + block._type)
        return null
    }

  })

  return (
      <Layout pageContext={pageContext}>
        <Seo pageContext={pageContext} />
        <section className="home-body">
          {bodyElements}
        </section>
      </Layout>
  )
}

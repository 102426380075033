import React from "react"
import BlockPortableText from "../components/BlockPortableText"
import EventHeader from "../components/EventHeader"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

export default function Event({ pageContext }) {

  // Destructure pageContext
  const { lang, event, defaultSlugs } = pageContext
  const { body } = event

  return (
      <Layout pageContext={pageContext}>
        <Seo pageContext={pageContext} />
        <EventHeader event={event} lang={lang} defaultSlugs={defaultSlugs} />
        {body && <BlockPortableText body={body} lang={lang} />}
      </Layout>
  )
}

import React from "react"
import BlockPortableText from "../components/BlockPortableText"
import Layout from "../components/Layout"
import LineupGrid from "../components/LineupGrid"
import Seo from "../components/Seo"

export default function Lineup({ pageContext }) {
  
  const { page, lang, defaultSlugs } = pageContext
  const { lineup, body } = page

  return (
      <Layout pageContext={pageContext}>
        <Seo pageContext={pageContext} />
        <LineupGrid lineup={lineup} lang={lang} context="lineup" defaultSlugs={defaultSlugs} />
        {body && (
          <section className="content-body">
            <BlockPortableText body={body} lang={lang} />
          </section>
        )}
      </Layout>
  )
}
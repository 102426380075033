import { Link } from "gatsby"
import React from "react"
import EventTitle from "./EventTitle"
import ThumbImage from "./ThumbImage"
import { groupEventsbyLabel, sortEvents } from "../tools/utils"

export default function ScheduleGrid({ schedule, lang, context, hidePastEvents }) {

    let yesterday = new Date(new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)).setUTCHours(23,59,59,999))

    function createCardElements(events) {

        const elements = events.map(event => {
            if ((hidePastEvents && new Date(event.node.date.day) > yesterday) || !hidePastEvents) {
            return (
                <li className="event-card" key={event.node._id}>
                <Link to={`/${lang}/${event.node.section.slug.current}/${event.node.slug.current}`}>
                    <ThumbImage image={event.node.cover} title={event.node.title} scheme={event.node.scheme || 'fill-red'} lang={lang} />
                    <EventTitle event={event.node} lang={lang} context="schedule"/>
                </Link>
                </li>
            )
            } else return null
        })

        return elements
    }

    let groupElements = []
    
    if (context === "person") {

        const sortedSchedule = sortEvents(schedule)
        groupElements = (
            <div className="schedule-group">
                <ul className="event-list">
                    {createCardElements(sortedSchedule)}
                </ul>
            </div>
        )

    } else if (context === "home") {

        const sortedSchedule = schedule.list.map(event => ({ node: event }))
        const groupLabel = schedule.label?.[lang] ? schedule.label[lang] : ""
        groupElements = (
            <li className="schedule-group">
                {groupLabel && <h3 className="group-label title">{groupLabel}</h3>}
                <ul className="event-list">
                    {createCardElements(sortedSchedule)}
                </ul>
            </li>
        )

    } else {

        const sortedSchedule = groupEventsbyLabel(sortEvents(schedule), lang)
        groupElements = sortedSchedule.map((group, i) => {
    
            const groupLabel = group.label?.[lang] ? group.label[lang] : ""
            const cardsElements = createCardElements(group.events)
    
            return (
                <li className="schedule-group" key={i}>
                    {groupLabel && <h3 className="group-label title">{groupLabel}</h3>}
                    <ul className="event-list">
                        {cardsElements}
                    </ul>
                </li>
            )
    
        })

    }

  return (
    <ul className="schedule-grid">
        {groupElements}
    </ul>
  )
}

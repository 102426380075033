import React from "react"
import { Link } from "gatsby"
import ThumbImage from "./ThumbImage"

export default function LineupGrid({ lineup, lang, context, defaultSlugs }) {

  let lineupElements = []
  
  function createCardElements(persons) {
    const cardsElements = persons.map(person => {
      const personName = person.name && person.name[lang] ? person.name[lang] : ""
      return (
        <div className="person-card" key={person._id}>
          <Link to={`/${lang}/${defaultSlugs.people}/${person.slug.current}`}>
            <div className="person-name title-highlight">{personName}</div>
            <ThumbImage image={person.photo} title={person.name} lang={lang} />
          </Link>
        </div>
      )
    })
    return cardsElements
  }

  if (context === "home") {
    const label = lineup.label && lineup.label[lang] ? lineup.label[lang] : ""
    lineupElements = (
      <div className="lineup-group">
        {label && <h3 className="group-label title">{label}</h3>}
        <div className="lineup-list">
          {createCardElements(lineup.list)}
        </div>
      </div>
    )
  } else {
    lineupElements = lineup.map(group => {
      const groupLabel = group.label && group.label[lang] ? group.label[lang] : ""
  
      const listElements = group.list.map(person => {
        const personName = person.name && person.name[lang] ? person.name[lang] : ""
  
        return (
          <div className="person-card" key={person._id}>
            <Link to={`/${lang}/${defaultSlugs.people}/${person.slug.current}`}>
              <ThumbImage image={person.photo} title={person.name} lang={lang} />
              <div className="person-name title-highlight">{personName}</div>
            </Link>
          </div>
        )
      })
  
      return (
        <div className="lineup-group" key={group._key}>
          {groupLabel && <h3 className="group-label title">{groupLabel}</h3>}
          <div className="lineup-list">
            {listElements}
          </div>
        </div>
      )
    })
  }
  
  return (
    <div className="lineup-grid">
        {lineupElements}
    </div>
  )
}

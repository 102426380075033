import React from "react"
import BlockPortableText from "../components/BlockPortableText"
import Layout from "../components/Layout"
import ScheduleGrid from "../components/ScheduleGrid"
import Seo from "../components/Seo"

export default function Schedule({ pageContext }) {
  
  const { schedule, lang } = pageContext
  const { hidePastEvents, body } = pageContext.page

  return (
      <Layout pageContext={pageContext}>
        <Seo pageContext={pageContext} />
        <ScheduleGrid schedule={schedule} lang={lang} context="schedule" hidePastEvents={hidePastEvents} />
        {body && (
          <section className="content-body">
            <BlockPortableText body={body} lang={lang} />
          </section>
        )}
      </Layout>
  )
}
